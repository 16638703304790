<template>
  <div class="info">
    <Card>
      <template slot="title">
        <div class="card-info">
          <h3 class="name">{{data.qbsAccountName}}</h3>
          <span
            class="tag"
            v-show="data.leadsLabel"
          >{{handletype(data.leadsLabel)}}</span>
        </div>
      </template>
      <div class="cu_cardcell">
        <span>{{data.qbsAccountPhone}}</span>
        <span style="margin: 0 8px;">·</span>
        <span>{{data.intentionCity}}</span>
      </div>
      <CardCell
        :label="data.intentionCarSeries"
        :value="data.threeLevelChannel"
        justify="space-between"
        :colon="false"
      />
      <template slot="footer">
        <span class="footer-time">{{handletime(data.createtime)}}</span>
        <div v-if="state === 0">
          <Button
            round
            plain
            hairline
            type="info"
            size="small"
            style="width: 80px; margin-right: 10px;"
            v-auth="'GuestPool-Distribution'"
            @click="showPopup(data)"
          >分配</Button>
          <Button
            round
            type="info"
            size="small"
            style="width: 80px;"
            @click="receive(data)"
            v-auth="'GuestPool- Claim'"
          >认领</Button>
        </div>
      </template>
    </Card>
  </div>
</template>
<script>
import { Button, Dialog, Toast } from 'vant'
import { taokeClaim } from '@/api/chargequery'
import Card from '_c/card'
import CardCell from '_c/card/cardCell'
import dayjs from 'dayjs'
export default {
  data () {
    return {
      show: false
    }
  },
  props: {
    data: {
      type: Object
    },
  },
  components: {
    Button,
    Card,
    CardCell,
    // Claim
  },
  inject: ['getState'],
  computed: {
    state () {
      return this.getState()
    }
  },
  methods: {
    // 分配按钮
    showPopup (cardInfo) {
      //传值分配显示选项
      cardInfo.Distribution = '2'
      this.$router.push({
        path: '/taoGuestPoolAllot',
        query: cardInfo
      })
    },
    // 认领按钮 
    receive (info) {
      Dialog.confirm({
        title: info.qbsAccountName,
        message: info.qbsAccountPhone,
      }).then(() => {
        this.taokeClaim(info.eosLeadsId)
      }).catch(() => {
        // on cancel
      })
    },
    // 处理类型
    handletype (type) {
      if (type === '1') return '常规留资'
      if (type === '2') return '试驾'
      if (type === '3') return '金融'
    },
    // 
    handletime (ctime) {
      const time = ctime ? dayjs(ctime).format('YYYY-MM-DD HH:mm:ss') : ''
      return time
    },
    // 认领
    async taokeClaim (eosLeadsId) {
      const res = await taokeClaim({ leadsId: eosLeadsId })
      if (res.code === '000000') {
        Toast('认领成功')
        this.$emit('Claimsuccess')
      } else {
        Toast(res.msg)
      }
    }
  }
}
</script>
<style lang='less' scoped>
@import "@/style/common.less";
.info {
  font-size: 24px;

  .card-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .name {
      width: 200px;
      margin-right: 20px;
    }

    .tag {
      display: inline-block;
      padding: 0 10px;
      border: 1px solid;
      border-radius: 8px;
      color: @color-blue;
      height: 42px;
      line-height: 42px;
    }
  }
}
.footer-time {
  font-size: 24px;
  margin-top: 12px;
}
.cu_cardcell {
  font-size: 24px;
  line-height: 34px;
  color: #666;
}
</style>